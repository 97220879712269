<script>
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BTable } from 'bootstrap-vue';
import { generateIconColorToBoolean, generateIconToBoolean } from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';
import { checkLocales } from '@/util/lang';
import api from '@/services/api';
import FlatPickr from 'vue-flatpickr-component';

export default {
    name: 'AppNewStatistics',
    components: {
        ProgressLinear,
        PageTitle,
        BTable,
        FlatPickr
    },
    data() {
        return {
            total: null,
            dailyItems: [],
            fetchingDailyItems: false,
            dailyProductItems: [],
            fetchingDailyProductItems: false,
            productItems: [],
            fetchingProductItems: false,
            params: {
                date_start: null,
                date_end: null
            }
        };
    },

    computed: {
        dailyFields() {
            return [
                {
                    key: 'count',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                }
            ];
        },

        dailyProductFields() {
            return [
                {
                    key: 'product',
                    label: this.$t('titles.product')
                },
                {
                    key: 'count',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                },
                {
                    key: 'date',
                    label: this.$t('titles.date')
                }
            ];
        },

        productFields() {
            return [
                {
                    key: 'product',
                    label: this.$t('titles.product')
                },
                {
                    key: 'count',
                    label: this.$t('titles.qty')
                },
                {
                    key: 'sum',
                    label: this.$t('titles.sum')
                }
            ];
        }
    },

    async mounted() {
        await this.getDatesForParam();
        this.fetchingDailyItems = true;
        this.fetchingDailyProductItems = true;
        this.fetchingProductItems = true;
        await this.applyFilter();
    },

    methods: {
        checkLocales,

        async getDatesForParam() {
            const date = new Date();

            const year = date.getUTCFullYear(); // Get the full year (4 digits)
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1 and pad with leading zero if necessary
            const day = String(date.getDate()).padStart(2, '0'); // Pad day with leading zero if necessary

            this.params.date_start = `${year}-${month}-${day}`;
            this.params.date_end = `${year}-${month}-${day}`;
        },

        async getTotalCount() {
            const params = {
                merchant_id: parseInt(MERCHANT_ID),
                date_start: this.params.date_start,
                date_end: this.params.date_end
            };

            try {
                const { data } = await api.statisticsV2.fetchTotalCount(params);
                this.total = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getDaily() {
            const params = {
                merchant_id: parseInt(MERCHANT_ID),
                date_start: this.params.date_start,
                date_end: this.params.date_end
            };

            try {
                const { data } = await api.statisticsV2.fetchDaily(params);
                this.dailyItems = data.data;
            } catch (e) {
                console.error(e);
            } finally {
                this.fetchingDailyItems = false;
            }
        },

        async getDailyProducts() {
            const params = {
                merchant_id: parseInt(MERCHANT_ID),
                date_start: this.params.date_start,
                date_end: this.params.date_end
            };

            try {
                const { data } = await api.statisticsV2.fetchDailyProduct(params);
                this.dailyProductItems = data.data;
            } catch (e) {
                console.error(e);
            } finally {
                this.fetchingDailyProductItems = false;
            }
        },

        async getProducts() {
            const params = {
                merchant_id: parseInt(MERCHANT_ID),
                date_start: this.params.date_start,
                date_end: this.params.date_end
            };

            try {
                const { data } = await api.statisticsV2.fetchProducts(params);
                this.productItems = data.data;
            } catch (e) {
                console.error(e);
            } finally {
                this.fetchingProductItems = false;
            }
        },

        async applyFilter() {
            await this.getTotalCount();
            await this.getProducts();
            await this.getDaily();
            await this.getDailyProducts();
        },

        changePerPage(perPage) {
            this.pagination = {
                perPage,
                page: 1
            };
            this.getAll();
        },

        beforeClose() {
            this.uploadImage = null;
            this.onCloseModal();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        formatNumber(num) {
            if (num) {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
        },

        generateIconToBoolean,
        generateIconColorToBoolean
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('titles.charges_statistics') }}</template>
        </page-title>

        <div class="row d-flex flex-column flex-sm-row align-items-center mb-2">
            <div class="col-12 col-sm-10 col-lg-11 px-0 d-sm-flex align-items-center">
                <div class="col-12 col-sm-6 px-1">
                    <flat-pickr
                        v-model="params.date_start"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                    />
                </div>
                <div class="col-12 col-sm-6 mt-2 mt-sm-0 px-1">
                    <flat-pickr
                        v-model="params.date_end"
                        :config="{
                            allowInput: true,
                            time_24hr: true
                        }"
                        :placeholder="$t('choose.date')"
                        class="form-control"
                        name="date"
                    />
                </div>
            </div>
            <div class="col-12 col-sm-2 col-lg-1">
                <b-button
                    @click="applyFilter"
                    variant="outline-primary"
                    class="px-1 px-sm-0 d-block mx-auto mt-2 mt-sm-0 w-100"
                >
                    <feather-icon icon="SearchIcon" size="16" />
                </b-button>
            </div>
        </div>

        <div class="card p-1 d-flex flex-row justify-content-around">
            <div class="d-flex">
                <h3 class="mr-1">{{ $t('titles.total_count') }}:</h3>
                <b-badge variant="success">{{ formatNumber(total.count) }}</b-badge>
            </div>
            <div class="d-flex">
                <h3 class="mr-1">{{ $t('titles.total_sum') }}:</h3>
                <b-badge variant="primary">{{ formatNumber(total.sum) }}</b-badge>
            </div>
        </div>

        <!--  PRODUCTS TABLE  -->
        <div class="card">
            <progress-linear v-if="fetchingProductItems" />
            <b-table
                v-else
                :fields="productFields"
                :items="productItems"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(count)="{ item }">{{ formatNumber(item.count) }}</template>
                <template #cell(sum)="{ item }">{{ formatNumber(item.sum) }}</template>
            </b-table>
        </div>

        <!--  DAILY TABLE  -->
        <div class="card">
            <progress-linear v-if="fetchingDailyItems" />
            <b-table
                v-else
                :fields="dailyFields"
                :items="dailyItems"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(count)="{ item }">{{ formatNumber(item.count) }}</template>
                <template #cell(sum)="{ item }">{{ formatNumber(item.sum) }}</template>
            </b-table>
        </div>

        <!--  DAILY PRODUCTS TABLE  -->
        <div class="card">
            <progress-linear v-if="fetchingDailyProductItems" />
            <b-table
                v-else
                :fields="dailyProductFields"
                :items="dailyProductItems"
                :responsive="true"
                borderless
                class="news__table"
                outlined
                striped
            >
                <template #cell(count)="{ item }">{{ formatNumber(item.count) }}</template>
                <template #cell(sum)="{ item }">{{ formatNumber(item.sum) }}</template>
            </b-table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.badge {
    font-size: 20px;
}
</style>
