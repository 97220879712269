var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('titles.charges_statistics')))]},proxy:true}])}),_c('div',{staticClass:"row d-flex flex-column flex-sm-row align-items-center mb-2"},[_c('div',{staticClass:"col-12 col-sm-10 col-lg-11 px-0 d-sm-flex align-items-center"},[_c('div',{staticClass:"col-12 col-sm-6 px-1"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                        allowInput: true,
                        time_24hr: true
                    },"placeholder":_vm.$t('choose.date'),"name":"date"},model:{value:(_vm.params.date_start),callback:function ($$v) {_vm.$set(_vm.params, "date_start", $$v)},expression:"params.date_start"}})],1),_c('div',{staticClass:"col-12 col-sm-6 mt-2 mt-sm-0 px-1"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                        allowInput: true,
                        time_24hr: true
                    },"placeholder":_vm.$t('choose.date'),"name":"date"},model:{value:(_vm.params.date_end),callback:function ($$v) {_vm.$set(_vm.params, "date_end", $$v)},expression:"params.date_end"}})],1)]),_c('div',{staticClass:"col-12 col-sm-2 col-lg-1"},[_c('b-button',{staticClass:"px-1 px-sm-0 d-block mx-auto mt-2 mt-sm-0 w-100",attrs:{"variant":"outline-primary"},on:{"click":_vm.applyFilter}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"16"}})],1)],1)]),_c('div',{staticClass:"card p-1 d-flex flex-row justify-content-around"},[_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('titles.total_count'))+":")]),_c('b-badge',{attrs:{"variant":"success"}},[_vm._v(_vm._s(_vm.formatNumber(_vm.total.count)))])],1),_c('div',{staticClass:"d-flex"},[_c('h3',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.$t('titles.total_sum'))+":")]),_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.formatNumber(_vm.total.sum)))])],1)]),_c('div',{staticClass:"card"},[(_vm.fetchingProductItems)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.productFields,"items":_vm.productItems,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(count)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(_vm._s(_vm.formatNumber(item.count)))]}},{key:"cell(sum)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(_vm._s(_vm.formatNumber(item.sum)))]}}])})],1),_c('div',{staticClass:"card"},[(_vm.fetchingDailyItems)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.dailyFields,"items":_vm.dailyItems,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(count)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(_vm._s(_vm.formatNumber(item.count)))]}},{key:"cell(sum)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(_vm._s(_vm.formatNumber(item.sum)))]}}])})],1),_c('div',{staticClass:"card"},[(_vm.fetchingDailyProductItems)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.dailyProductFields,"items":_vm.dailyProductItems,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(count)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(_vm._s(_vm.formatNumber(item.count)))]}},{key:"cell(sum)",fn:function(ref){
                    var item = ref.item;
return [_vm._v(_vm._s(_vm.formatNumber(item.sum)))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }